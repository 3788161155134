import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// 公共路由
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/pages/index'),
    hidden: true
  },
  {
    path: '/about',
    component: () => import('@/pages/about'),
    hidden: true
  },
  {
    path: '/download',
    component: () => import('@/pages/download'),
    hidden: true
  },
  {
    path: '/contact',
    component: () => import('@/pages/contact'),
    hidden: true
  }
];

// 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(err => err);
// };

export default new Router({
  mode: 'hash', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
});
